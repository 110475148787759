<template>
  <div>
         <div class="search">
        <div class="title">入园地列表</div>
        <hr />
      <el-form :inline="true"  class="top">
        <el-form-item label="入园地点:">
            <el-input v-model="params.parkName" ></el-input>
        </el-form-item>
        <!-- <el-form-item label="手机号:">
            <el-input v-model="params.phone" ></el-input>
        </el-form-item> -->
            <!-- <el-form-item label="时间:">
                 <el-date-picker
                    :editable='false'
                    :picker-options="pickerOptions0"
                    value-format='yyyy-MM-dd HH:mm:ss'
                    v-model="date"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
             </el-form-item> -->
         <el-form-item >
            <el-button type="primary"  @click="getList">查询</el-button>
            <el-button type="primary"   @click="reset">重置</el-button>
            <el-button type="primary"   @click="add">添加</el-button>
        </el-form-item>
      
        </el-form>
     </div>
        <el-table
        :data="tableData"
        border
        style="width: 100%"
        @selection-change="selectionChange"
        align="center">
         <el-table-column type="selection" width="55"  align="center"> </el-table-column>
        <el-table-column type="index" width="50"   align="center"> </el-table-column>
        <el-table-column prop="parkId" width="80" label="ID"  align="center"> </el-table-column>
        <el-table-column
        prop="parkName"
        label="入园地点"
        
        align="center">
        </el-table-column>
        <el-table-column
        label="场所码"
        width="180"
        align="center">
        <template slot-scope="scope">
            <el-image 
                style="width: 70px; height: 70px"
                :src="scope.row.erCode" 
                :preview-src-list="[scope.row.erCode]">
            </el-image>
        </template>
        </el-table-column>
         <el-table-column
        prop="parkAdd"
        label="地址"
   
        align="center">
        </el-table-column>
      
         <el-table-column
        prop="addTime"
        label="注册时间"
        width="200"
        align="center">
        </el-table-column>
        <el-table-column
        label="操作"
        align="center"
        >
        <template slot-scope="scope">
          
            <el-button type="primary"  size="mini" @click="remove(scope.row)">删除</el-button>
            <el-button type="primary"  size="mini" @click="edit(scope.row)">编辑</el-button>
            <el-button type="primary"  size="mini" @click="code(scope.row)">生成小程序码</el-button>
            <el-button type="primary"  size="mini" @click="details(scope.row)">查看登记详情</el-button>
           
        </template>
        </el-table-column>
    </el-table>
         <div class="block">
         <div>
              <el-button type="primary" @click="allremove">批量删除</el-button>
            <!-- <el-button type="primary" @click="allOpe">批量操作</el-button> -->
         </div>
        <div>
              <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.currentPage"
            :page-sizes="[5,10,20]"
            :page-size="params.pageSize"
            layout="total,sizes,prev,pager,next, jumper"
            :total="total"
        >
        </el-pagination>
        </div>
     </div>
     <el-dialog
    :title="submitForm ? '编辑': '添加'"
    :visible.sync="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
    @close="close">
        <el-form ref="submitFormRef" :model="submitForm" :rules="submitFormRules" label-width="120px">
            <el-form-item label="入园地点名称" prop="parkName">
                <el-input v-model="submitForm.parkName"></el-input>
            </el-form-item>
            <el-form-item label="入园地址" prop="parkAdd">
                <el-input v-model="submitForm.parkAdd"></el-input>
            </el-form-item>
              <el-form-item label="场所码" prop="erCode">
            <el-upload
                    class="avatar-uploader"
                    :action="baseUrl+'/upload/fileUpload'"
                    :show-file-list="false"
                    :on-success="onSuccessShowImg"
                    >
                    <img v-if="submitForm.erCode" :src="submitForm.erCode" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
          </el-form-item>
        </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFormClick">确 定</el-button>
    </span>
    </el-dialog>
    
            <el-dialog
          title="提示"
          :visible.sync="codeVisible"
          width="30%"
          
          >
          <div class="inner_top">
   <el-input
        style="width:300px"
        v-model.trim="QRcodeSize"
        placeholder="请输入生成的二维码大小"
        ></el-input>
        
          <el-button type="primary" @click="generateQRcode">生成</el-button>
        </div>
                <div style="color:red">推荐宽度：280px-1280px</div>
        <el-image
            
            v-show="imgUrl"
            fit="fill"
            :src="imgUrl"
          ></el-image>
  <span slot="footer" class="dialog-footer">
    <span>右键点击图片，点击另存为保存图片</span>
    <el-button @click="codeVisible = false">取 消</el-button>
    <el-button type="primary" @click="codeVisible = false">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import { getRegList,regListAdd,regListDel,regListCode } from '../../api/administrator.js'
export default {
    data() {
        return {
            submit:false,
            QRcodeSize:'',
            imgUrl:'',
            dialogVisible:false,
            codeVisible:false,
            total: 0,
            params: {
                currentPage: 1,
                pageSize: 5,
                parkName: '',
               
                // phone: '',
                // beginTime: '',
                // endTime: '',
            },
            submitForm:{
                parkId:0,
                parkName:'',
                parkAdd:'',
                 erCode:''
            },
            ids:'',
            submitFormRules:{
                  parkName: [
                        { required: true, message: '请输入入园地点名称', trigger: 'blur' },
                       
                    ],
                  parkAdd: [
                        { required: true, message: '请输入入园地址', trigger: 'blur' },
                       
                    ],
                     erCode: [
                        { required: true, message: '请上传场所码', trigger: 'blur' },
                       
                    ],
            },
            tableData: [],
            date: null,
            parkId:'',
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now() - 8.64e6
                },
            }
        }
    },
    created(){
        this.getList()
    },
    methods: {
         // 封面图片上传
        onSuccessShowImg(e){
            this.submitForm.erCode = e.data[0].src
        },
          async generateQRcode(){
            this.imgUrl = ''
            if(!this.QRcodeSize){
            this.$message({ message: "请输入宽度", type: "warning" }); 
            }else{
            const {data} = await regListCode({
                parkId:this.parkId,
                widthSize:this.QRcodeSize
            })
            this.imgUrl = data.msg
            console.log(this.imgUrl);
            }
            
            },
        details(row){
            this.$router.push('/allRegList?id='+row.parkId)
        },
        selectionChange(e){
            console.log(e)
            this.ids = e.map(item=>item.parkId).join(',')
            console.log(this.ids)
        },
        async getList(){
            // this.params.beginTime = this.date ?  this.date[0] : ''
            // this.params.endTime = this.date ? this.date[1] : ''
            const {data} = await getRegList(this.params)
            if(data.code == 0){
                this.tableData = data.list
                this.total = data.pagination.total
            }else{
                this.$message.warning(data.msg)
            }
        },
          handleSizeChange(e){
            this.params.pageSize = e
            this.getList()
        },
          // 页数改变
        handleCurrentChange(e){
            this.params.currentPage = e
            this.getList()
        },
        code(row){
            this.codeVisible = true
             this.imgUrl = ''
            this.parkId = row.parkId
        },
        reset(){
           
            this.params.currentPage = 1
            this.params.pageSize = 5
            this.params.parkName = ''
            // this.params.beginTime = ''
            // this.params.endTime = ''
            // this.date = null
            this.getList()
        },
        close(){
            console.log(11111)
            
            this.$refs.submitFormRef.resetFields()
        },
        allremove(){
            if(!this.ids){
                 this.$message({
                        type: 'warning',
                        message: '请选择需要删除的入园地'
                    })
                    return
            }
               this.$confirm('此操作将永久删选中的入园地, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(async () => {
                const {data} = await regListDel({ids:this.ids})
                if(data.code == 0){
                    this.getList()
                     this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }else{
                     this.$message({
                        type: 'warning',
                        message: data.msg
                    });
                }
            }).catch(() => {
                   
            });
        },
        add(){
             this.submitForm.parkId = 0
               this.submitForm.parkName = ''
                this.submitForm.parkAdd = ''
                 this.submitForm.erCode = ''
            this.dialogVisible = true
        },
        edit(row){
             this.submitForm.parkId = row.parkId
               this.submitForm.parkName = row.parkName
                this.submitForm.parkAdd = row.parkAdd
                 this.submitForm.erCode = row.erCode
                this.dialogVisible = true
        },
        remove(row){
            this.$confirm('此操作将永久删除该入园地, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(async () => {
                const {data} = await regListDel({ids:row.parkId})
                if(data.code == 0){
                    this.getList()
                     this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                }else{
                     this.$message({
                        type: 'warning',
                        message: data.msg
                    });
                }
            }).catch(() => {
                   
            });
        },
        submitFormClick(){
            this.$refs.submitFormRef.validate(async (validate)=>{
                if(validate){
                    if(this.submit) return
                     this.submit = true
                    const {data} = await regListAdd(this.submitForm)
                    if(data.code == 0){
                        this.$message.success(data.msg)
                        this.dialogVisible = false
                        this.getList()
                    }else{
                          this.$message.warning(data.msg)
                    }
                    setTimeout(() => {
                        this.submit = false
                    }, 1000);
                }
            })
        },
    }
}
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top{
    margin-top: 20px;
}
.block{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
</style>